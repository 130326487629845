import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PERMISSION } from '../constant';
import Loading from '../pages/Loading';

const AccountPage = lazy(() => import('../pages/account'));
const AgentPage = lazy(() => import('../pages/agent'));
const AggregatePage = lazy(() => import('../pages/aggregate'));
const AssetFlowLitePage = lazy(() => import('../pages/assetFlow/AssetFlowLite'));
const AssetFlowPage = lazy(() => import('../pages/assetFlow'));
const ContractAddressPage = lazy(() => import('../pages/contractAddress'));
const CurrencyPage = lazy(() => import('../pages/currency'));
const ErrorPage403 = lazy(() => import('../pages/403'));
const ErrorPage404 = lazy(() => import('../pages/404'));
const ErrorPage500 = lazy(() => import('../pages/500'));
const HomePage = lazy(() => import('../pages/home'));
const InvitationPage = lazy(() => import('../pages/invitation'));
const LinkPayReportPage = lazy(() => import('../pages/linkPayReport'));
const LoginPage = lazy(() => import('../pages/login'));
const MemberPage = lazy(() => import('../pages/member'));
const MerchantLitePage = lazy(() => import('../pages/merchantLite'));
const MerchantLiteWalletPage = lazy(() => import('../pages/merchantLiteWallet'));
const MerchantProPage = lazy(() => import('../pages/merchantPro'));
const MerchantStdPage = lazy(() => import('../pages/merchantStd'));
const MerchantProWalletPage = lazy(() => import('../pages/merchantProWallet'));
const MerchantStdWalletPage = lazy(() => import('../pages/merchantStdWallet'));
const MultisigPage = lazy(() => import('../pages/multisig'));
const NetworkPage = lazy(() => import('../pages/network'));
const OperatorPage = lazy(() => import('../pages/operator'));
const PayLinkPage = lazy(() => import('../pages/payLink'));
const PaymentReportPage = lazy(() => import('../pages/paymentReport'));
const PrivateRoute = lazy(() => import('../pages/layout/PrivateRoute'));
const RechargeReportPage = lazy(() => import('../pages/rechargeReport'));
const RefundPage = lazy(() => import('../pages/refund'));
const RegisterPage = lazy(() => import('../pages/register'));
const TermsPage = lazy(() => import('../pages/terms'));
const TransferPage = lazy(() => import('../pages/transfer'));
const WithdrawPage = lazy(() => import('../pages/withdraw'));
const BrandPage = lazy(() => import('../pages/brand'));
const GasReportPage = lazy(() => import('../pages/gasReport'));
const OrderRepairPage = lazy(() => import('../pages/orderRepair'));

export const routes = [
  {
    path: '/',
    component: <Navigate to="/home" />,
    roles: PERMISSION.all,
  },
  {
    path: '/home',
    component: <HomePage />,
    roles: PERMISSION.all,
  },
  {
    path: '/network',
    component: <NetworkPage />,
    roles: PERMISSION.all,
  },
  {
    path: '/currency',
    component: <CurrencyPage />,
    roles: PERMISSION.all,
  },
  {
    path: '/multisig',
    component: <MultisigPage />,
    roles: PERMISSION.exludeLite,
  },
  {
    path: '/transfer',
    component: <TransferPage />,
    roles: PERMISSION.all,
  },
  {
    path: '/withdraw',
    component: <WithdrawPage />,
    roles: PERMISSION.all,
  },
  {
    path: '/refund',
    component: <RefundPage />,
    roles: PERMISSION.all,
  },
  {
    path: '/merchant-pro-wallet',
    component: <MerchantProWalletPage />,
    roles: PERMISSION.merchantPro,
  },
  {
    path: '/merchant-std-wallet',
    component: <MerchantStdWalletPage />,
    roles: PERMISSION.merchantStd,
  },
  {
    path: '/operator',
    component: <OperatorPage />,
    roles: PERMISSION.operator,
  },
  {
    path: '/agent',
    component: <AgentPage />,
    roles: PERMISSION.agent,
  },
  {
    path: '/merchant-pro',
    component: <MerchantProPage />,
    roles: PERMISSION.merchantPro,
  },
  {
    path: '/merchant-std',
    component: <MerchantStdPage />,
    roles: PERMISSION.merchantStd,
  },
  {
    path: '/merchant-lite',
    component: <MerchantLitePage />,
    roles: PERMISSION.merchantLite,
  },
  {
    path: '/aggregate',
    component: <AggregatePage />,
    roles: PERMISSION.all,
  },
  {
    path: '/member',
    component: <MemberPage />,
    roles: PERMISSION.all,
  },
  {
    path: '/contract-address',
    component: <ContractAddressPage />,
    roles: PERMISSION.exludeAgent,
  },
  {
    path: '/invitation',
    component: <InvitationPage />,
    roles: PERMISSION.agent,
  },
  {
    path: '/asset-flow',
    component: <AssetFlowPage />,
    roles: PERMISSION.exludeAgent,
  },
  {
    path: '/asset-flow-lite',
    component: <AssetFlowLitePage />,
    roles: PERMISSION.exludeAgent,
  },
  {
    path: '/merchant-lite-wallet',
    component: <MerchantLiteWalletPage />,
    roles: PERMISSION.merchantLite,
  },
  {
    path: '/pay-link',
    component: <PayLinkPage />,
    roles: PERMISSION.exludeAgent,
  },
  {
    path: '/payment-report',
    component: <PaymentReportPage />,
    roles: PERMISSION.exludeAgent,
  },
  {
    path: '/linkpay-report',
    component: <LinkPayReportPage />,
    roles: PERMISSION.exludeAgent,
  },
  {
    path: '/recharge-report',
    component: <RechargeReportPage />,
    roles: PERMISSION.exludeAgent,
  },
  {
    path: '/gas-report',
    component: <GasReportPage />,
    roles: PERMISSION.exludeAgent,
  },
  {
    path: '/order-repair',
    component: <OrderRepairPage />,
    roles: PERMISSION.exludeAgent,
  },
  {
    path: '/account',
    component: <AccountPage />,
    roles: PERMISSION.all,
  },
  {
    path: '/brand',
    component: <BrandPage />,
    roles: PERMISSION.operator,
  },
];

function AppRoutes() {
  return (
    <Routes>
      {routes.map((route) => (
        <Route
          exact
          key={route.path}
          path={route.path}
          element={<PrivateRoute allowedRoles={route.roles} />}
        >
          <Route
            exact
            path={route.path}
            element={<Suspense fallback={<Loading white />}>{route.component}</Suspense>}
          />
        </Route>
      ))}

      {/* Auth */}
      <Route
        path="/terms"
        element={<Suspense fallback={<Loading />}><TermsPage /></Suspense>}
      />
      <Route
        path="/login"
        element={<Suspense fallback={<Loading />}><LoginPage /></Suspense>}
      />
      <Route
        path="/login"
        element={<Suspense fallback={<Loading />}><LoginPage /></Suspense>}
      />
      <Route
        path="/signup"
        element={<Suspense fallback={<Loading />}><RegisterPage /></Suspense>}
      />
      <Route
        path="/403"
        element={<Suspense fallback={<Loading />}><ErrorPage403 /></Suspense>}
      />
      <Route
        path="/500"
        element={<Suspense fallback={<Loading />}><ErrorPage500 /></Suspense>}
      />
      <Route
        path="*"
        element={<Suspense fallback={<Loading />}><ErrorPage404 /></Suspense>}
      />
    </Routes>
  );
}

export default AppRoutes;
