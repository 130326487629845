import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'currentOnly',
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      queryStringParams: { hash: process.env.REACT_APP_GITHUB_SHA },
    },
  });

export const { t } = i18next;

export default i18next;
