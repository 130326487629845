/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

export const contractAddressSlice = createSlice({
  name: 'contractAddress',
  initialState: {
    MultiSigHotAddress: null,
    MultiSigHotStdAddress: null,
    MultiSigColdAddress: null,
    MultiSigColdStdAddress: null,
    MultiSigColdLiteAddress: null,
    MultiSigAgentAddress: null,
    MultiSigOperatorAddress: null,
    AgentFactoryAddress: null,
    AggregatedHelperAddress: null,
    ColdAddress: null,
    HotAddress: null,
    ToolHelperAddress: null,
  },
  reducers: {
    setContractAddress: (state, action) => {
      const {
        agentFactoryAddr,
        aggregatedHelperAddr,
        hotAddr,
        coldAddr,
        mulAgentAddr,
        mulHotAddr,
        mulHotStdAddr,
        mulColdAddr,
        mulColdStdAddr,
        mulColdLiteAddr,
        mulOperatorAddr,
        toolHelperAddr,
      } = action.payload;
      state.MultiSigHotAddress = mulHotAddr;
      state.MultiSigHotStdAddress = mulHotStdAddr;
      state.MultiSigColdAddress = mulColdAddr;
      state.MultiSigColdStdAddress = mulColdStdAddr;
      state.MultiSigColdLiteAddress = mulColdLiteAddr;
      state.MultiSigAgentAddress = mulAgentAddr;
      state.MultiSigOperatorAddress = mulOperatorAddr;
      state.AgentFactoryAddress = agentFactoryAddr;
      state.AggregatedHelperAddress = aggregatedHelperAddr;
      state.HotAddress = hotAddr;
      state.ColdAddress = coldAddr;
      state.ToolHelperAddress = toolHelperAddr;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setContractAddress,
} = contractAddressSlice.actions;

export default contractAddressSlice.reducer;
