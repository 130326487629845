export const DATA_PER_PAGE = 10;
export const DATA_PER_PAGE_LARGE = 100;
export const MULTI_SIG_COLD = 'MultiSigCold';
export const MULTI_SIG_COLD_STD = 'MultiSigColdStd';
export const MULTI_SIG_COLD_LITE = 'MultiSigColdLite';
export const MULTI_SIG_HOT = 'MultiSigHot';
export const MULTI_SIG_HOT_STD = 'MultiSigHotStd';
export const MULTI_SIG_AGENT = 'MultiSigAgent';
export const MULTI_SIG_OPERATOR = 'MultiSigOperator';
export const AGENT_FACTORY = 'AgentFactory';
export const AGGREGATED_HELPER = 'AggregatedHelper';
export const TOOL_HELPER = 'ToolHelper';

export const ORGTYPE = {
  operator: 0,
  agent: 1,
  // deprecated: 2,
  merchantPro: 3,
  merchantLite: 4,
  merchantStd: 5,
};

/** User role is ROLES[orgType][managerType] */
export const ROLES = [
  // [superAdmin, coldManger, hotManager, agentManager, soleManager, employee]
  [0x1n, 0x0n, 0x0n, 0x0n, 0x0n, 0x20n], // orgType 0, operator
  [0x0n, 0x0n, 0x0n, 0x800n, 0x0n, 0x2000n], // orgType 1, agent
  [0x0n, 0x0n, 0x0n, 0x0n, 0x0n, 0x0n], // orgType 2, deprecated
  [0x0n, 0x2000000n, 0x4000000n, 0x0n, 0x0n, 0x20000000n], // orgType 3, merchantPro
  [0x0n, 0x0n, 0x0n, 0x0n, 0x1000000000n, 0x2000000000n], // orgType 4, merchantLite(sole trader)
  [0x0n, 0x20000000000n, 0x40000000000n, 0x0n, 0x0n, 0x200000000000n], // orgType 5, merchantStd
];

export const ROLE = {
  superAdmin: ROLES[0][0],
  operatorEmployee: ROLES[0][5],
  agentManager: ROLES[1][3],
  agentEmployee: ROLES[1][5],
  merchantProManager: ROLES[3][1],
  merchantProFinance: ROLES[3][2],
  merchantProEmployee: ROLES[3][5],
  merchantLiteManager: ROLES[4][4],
  merchantLiteEmployee: ROLES[4][5],
  merchantStdManager: ROLES[5][1],
  merchantStdFinance: ROLES[5][2],
  merchantStdEmployee: ROLES[5][5],
};

const allRoles = ROLES.flat().reduce((acc, cur) => acc + cur, 0n);
const operatorRoles = ROLES[0].reduce((acc, cur) => acc + cur, 0n);
const agentRoles = ROLES[1].reduce((acc, cur) => acc + cur, 0n);
const merchantProRoles = ROLES[3].reduce((acc, cur) => acc + cur, 0n);
const merchantLiteRoles = ROLES[4].reduce((acc, cur) => acc + cur, 0n);
const merchantStdRoles = ROLES[5].reduce((acc, cur) => acc + cur, 0n);

export const PERMISSION = {
  all: allRoles,
  operator: operatorRoles,
  agent: agentRoles + operatorRoles,
  merchantPro: merchantProRoles + agentRoles + operatorRoles,
  merchantLite: merchantLiteRoles + agentRoles + operatorRoles,
  merchantStd: merchantStdRoles + agentRoles + operatorRoles,
  exludeAgent: allRoles - agentRoles,
  exludeLite: allRoles - merchantLiteRoles,
  agentSelf: agentRoles,
  allMerchants: merchantProRoles + merchantLiteRoles + merchantStdRoles,
  merchantProSelf: merchantProRoles,
  merchantLiteSelf: merchantLiteRoles,
  merchantStdSelf: merchantStdRoles,
  stdAndProMerchants: merchantProRoles + merchantStdRoles,
  allowTransfer: ROLE.merchantProFinance + merchantStdRoles + merchantLiteRoles,
  coldAdmins: ROLE.superAdmin + ROLE.agentManager + ROLE.merchantProManager
    + ROLE.merchantStdManager,
  merchantManagers: ROLE.merchantProManager + ROLE.merchantStdManager + ROLE.merchantLiteManager,
  allManagers: ROLE.superAdmin + ROLE.agentManager + ROLE.merchantProManager
    + ROLE.merchantStdManager + ROLE.merchantLiteManager,
};
